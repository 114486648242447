import React, { useState } from "react"
import { Container, Col, Row, Tab, Tabs } from "react-bootstrap"
import { Link } from "gatsby"
import MortgageRepayments from "../MortgageRepayments/MortgageRepayments"
import RentalYield from "../MortgageRepayments/RentalYield"
import "./assets/styles/_index.scss"
import { useMedia } from "react-use"
import ReactSelect from "react-select"

const OPTIONS = {
  mortgage: "Mortgage Calculator",
  rentalYeild: "Rental Yeild Calculator",
}

const PropertyDetailsCalculator = props => {
  const [selectedOption, setSelectedOption] = useState(OPTIONS.mortgage)

  const isTablet = useMedia("(max-width: 1024px)", false)

  const selectOptions = [
    {
      value: OPTIONS.mortgage,
      label: OPTIONS.mortgage,
    },
    {
      value: OPTIONS.rentalYeild,
      label: OPTIONS.rentalYeild,
    },
  ]

  const handleOnChange = key => {
    setSelectedOption(key)
  }

  return (
    <section className="property-details-calc-wrapper">
      <Row>
        <Col>
          <div className="property-details-scroll-heading calculator">
            Property Calculators
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          {isTablet ? (
            <>
              <ReactSelect
                options={selectOptions}
                onChange={option => handleOnChange(option.value)}
                className={"calculator-select"}
                classNamePrefix={"calculator-select"}
                defaultValue={selectOptions[0]}
                isSearchable={false}
              />
              {selectedOption === OPTIONS.mortgage && (
                <MortgageRepayments price={props?.price} />
              )}
              {selectedOption === OPTIONS.rentalYeild && (
                <RentalYield price={props?.price} />
              )}
            </>
          ) : (
            <Tabs
              defaultActiveKey={selectedOption}
              className="property-calc-tab"
              onSelect={handleOnChange}
            >
              <Tab eventKey={OPTIONS.mortgage} title="Mortgage">
                <MortgageRepayments price={props?.price} />
              </Tab>
              <Tab eventKey={OPTIONS.rentalYeild} title="Rental Yield">
                <RentalYield price={props?.price} />
              </Tab>
            </Tabs>
          )}
        </Col>
      </Row>
    </section>
  )
}

export default PropertyDetailsCalculator
