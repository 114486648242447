import React, { useRef } from "react"
import { Col, Row } from "react-bootstrap"
import { Link } from "gatsby"
import "./assets/styles/_index.scss"
import areaImg from "../../images/area-guide-img.png"
import { useMedia } from "react-use"

const PropertyDetailsAreaGuide = (props) => {
  const contentRef = useRef(null)
  const aboveTab = useMedia("(min-width: 1200px)", false)
  const isaboveTab = useMedia("(min-width: 993px)", false)
  return (
    <Row>
      <Col lg={8} md={6} className="order-md-1 order-2">
        <div ref={contentRef}>
          <div className="area-guide-heading">Dubai Harbour Area Guide</div>
          <p className="area-guide-text">
            Dubai Harbour is a luxurious waterfront development by Meraas
            Holding spanning over 20 million sq. ft. It is located at the
            intersection of the iconic Palm Jumeirah and Bluewaters Island, off
            King Salman bin Abdulaziz Al Saud Street in an area also known as
            Mina Al Seyahi.
          </p>
        </div>
        <Link to="#" className="area-guide-link">
          Continue Reading
        </Link>
      </Col>
      <Col lg={4} md={6} className="order-md-2 order-1">
        <img
          src={areaImg}
          className="property-area-img"
          style={{
            height: aboveTab
              ? contentRef?.current?.clientHeight - 16 || "164px"
              : isaboveTab ? "164px" : "",
          }}
        />
      </Col>
    </Row>
  )
}

export default PropertyDetailsAreaGuide